<template>
  <section class="section is-main-section">
    <card-component :title="formTitle" :icon="icon" :customIcon="customIcon">
      <ValidationObserver ref="observer">
        <form @submit.prevent="handleSubmitWithErrorScroll" @keydown.enter.prevent.self>
          <div class="columns">
            <div class="column">
              <DateSelector
                :value="assessmentDate"
                @input="v => $emit('setAssessmentDate', v)"
                label="Assessment Date"
                class="label-long"
                :disabled="readonly"
                :isLoading="isLoading"
                :past="true"
              />
            </div>
            <div class="column">
              <div class="columns">
                <div class="column">
                  <b-field label="DOB" horizontal>
                    <b-input v-model="dobForamtted" disabled />
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Age" horizontal message="At date of assessment">
                    <b-input v-model="form.age" disabled />
                  </b-field>
                </div>
              </div>
            </div>
            <div class="column">
              <b-field label="Gender" horizontal class="field-nested label-long">
                <PersonGender v-model="form.gender" :disabled="true" />
              </b-field>
            </div>
          </div>

          <slot />

          <ScoreRating v-if="showRating" :number="score" label="Score" :rating="rating" :riskType="riskType" />

          <div class="level has-text-centered scrollPaneFooter fullWidth is-mobile" :class="{ asideVisible: isAsideMobileExpanded }">
            <div class="level-item">
              <b-button @click="close">Close</b-button>
            </div>
            <div v-if="!readonly" class="level-item">
              <b-button type="is-primary" :loading="isSaving" native-type="submit" :disabled="saveDisabled">{{ saveText }}</b-button>
            </div>
          </div>

          <loading-data v-if="isLoading" />
        </form>
      </ValidationObserver>
    </card-component>
  </section>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import DateSelector from "@/components/DateSelector";
import ScoreRating from "@/components/ScoreRating";
import PersonGender from "@/components/profile/PersonGender.vue";

import Dates from "@/common/dates";
import alerts from "@/common/alerts";
import assessmentTypes from "@/enums/assessmentType";
import { mapGetters, mapState } from "vuex";
import LoadingData from "../../components/LoadingData.vue";
import ResidentMixin from "@/mixins/residentMixin";
import formMixin from "@/mixins/formMixin.js";

export default {
  mixins: [ResidentMixin, formMixin],
  components: { CardComponent, DateSelector, ScoreRating, PersonGender, LoadingData },
  props: {
    assessmentDate: { type: Date, required: true },
    score: { type: Number, required: false, default: 0 },
    rating: { validator: prop => typeof prop === "number" || prop === null, required: false },
    assessmentId: { type: Number, required: true, default: 0 },
    assessmentType: { type: Number, required: true },
    form: { type: Object, required: true },
    formPostData: { type: Function, require: false, default: () => null },
    showRating: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
    saveDisabled: { type: Boolean, default: false }
  },
  data() {
    return {
      isLoading: false,
      isSaving: false
    };
  },
  computed: {
    ...mapGetters("resident", ["residentAge", "residentMedicalGender", "residentDob"]),
    ...mapState(["isAsideMobileExpanded"]),

    dobForamtted() {
      return Dates.shortFormat(this.residentDob);
    },
    title() {
      return assessmentTypes.Lookup[this.assessmentType];
    },
    formTitle() {
      return (this.assessmentId ? "Edit " : "New ") + this.title + " Assessment";
    },
    url() {
      return assessmentTypes.Url[this.assessmentType] + "/";
    },
    path() {
      return assessmentTypes.Url[this.assessmentType];
    },
    riskType() {
      return assessmentTypes.RiskType[this.assessmentType];
    },
    saveText() {
      return this.assessmentId > 0 ? "Save Changes" : "Save New";
    },
    icon() {
      return assessmentTypes.Icon[this.assessmentType];
    },
    customIcon() {
      return assessmentTypes.CustomIcon[this.assessmentType];
    }
  },
  mounted() {
    // copy values from resident
    this.$emit("setResidentData", {
      residentId: this.residentId,
      age: this.residentAge,
      gender: this.residentMedicalGender
    });

    // if we have a id, get that assessment
    if (this.assessmentId) {
      this.getAssessmentData();
    }
  },
  watch: {
    assessmentId(newValue) {
      if (newValue) {
        this.getAssessmentData();
      }
    }
  },
  methods: {
    async submit() {
      if (!this.residentId) {
        alerts.showErrorAlert("Please select a Resident first");
        return;
      }

      this.isSaving = true;
      const data = this.formPostData() || { ...this.form, assessmentDate: Dates.formatYYYYMMDD(this.form.assessmentDate) };

      if (this.assessmentId) {
        await this.$http
          .put(this.url + this.assessmentId, data)
          .then(r => {
            this.$emit("dataSaved");
            this.$buefy.snackbar.open({ message: this.title + " updated" });
            this.close();
          })
          .catch(e => {
            alerts.showErrorAlert(e, "Error updating " + this.title + " details");
          });
      } else {
        // new assessment
        await this.$http
          .post(this.url, data)
          .then(r => {
            this.$emit("dataSaved");
            this.$buefy.snackbar.open({ message: "New " + this.title + " added" });
            this.close();
            // Is there a need to send update back to parent or update state? not at the moment
            // this.$emit("waterlowAdded", r.data);
            // return this.$store.dispatch("waterlow/addwWaterlow", r.data);
          })
          .catch(e => {
            alerts.showErrorAlert(e, "Error adding " + this.title + " details");
          });
      }
      this.isSaving = false;
    },
    async getAssessmentData() {
      this.isLoading = true;

      await this.$http
        .get(this.url + this.assessmentId)
        .then(r => {
          this.$emit("mapServerData", r.data);
          // set resident values from server ,
          this.$emit("setResidentData", {
            residentId: r.data.residentId,
            age: r.data.age,
            gender: r.data.gender
          });
        })
        .catch(e => {
          alerts.showErrorAlert(e, "Error loading " + this.title + " details");
          this.$router.replace({ name: this.path + ".new" });
        });
      this.isLoading = false;
    },
    close() {
      this.$router.go(-1);
    }
  }
};
</script>
